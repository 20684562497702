<!--空调模块--模拟设置表格组件-->
<template>
  <a-table :columns="columns" :data-source="slist" :scroll="{ x: 1800 }" bordered :pagination="false">
    <span slot="summerSetUpper" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue1_1" @change="e => inputChange(e.target.value, record.key, 'setValue1_1')"/>
    </span>
    <span slot="summerSetLower" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue1_2" @change="e => inputChange(e.target.value, record.key, 'setValue1_2')"/>
    </span>
    <span slot="summerValid" slot-scope="record"> 
      <a-radio-group :default-value="record.enable1_1 ? 1 : 2" @change="e =>radioChange(e.target.value, record.key, 'enable1_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span> 
    <span slot="winterSetUpper" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue2_1" @change="e => inputChange(e.target.value, record.key, 'setValue2_1')"/>
    </span>
    <span slot="winterSetLower" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue2_2" @change="e => inputChange(e.target.value, record.key, 'setValue2_2')"/>
    </span>
    <span slot="winterValid" slot-scope="record"> 
      <a-radio-group :default-value="record.enable2_1 ? 1 : 2" @change="e =>radioChange(e.target.value, record.key, 'enable2_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span> 
    <span slot="otherSetUpper" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue3_1" @change="e => inputChange(e.target.value, record.key, 'setValue3_1')"/>
    </span>
    <span slot="otherSetLower" slot-scope="record">      
      <a-input addon-after="℃" :default-value="record.setValue3_2" @change="e => inputChange(e.target.value, record.key, 'setValue3_2')"/>
    </span>
    <span slot="otherValid" slot-scope="record"> 
      <a-radio-group :default-value="record.enable3_1 ? 1 : 2" @change="e =>radioChange(e.target.value, record.key, 'enable3_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span>     
  </a-table>
</template>
<script>

export default {  
  props: {
    data: {
      type: Array,
    }    
  },
  data() {
    return {
      columns:[
        {
          title: "NO",
          dataIndex: "key",
          width: 70,
          fixed: "left",
          align: 'center'
        },
        {
          title: this.$t('controllseason.c14'),
          dataIndex: "dvName",
          align: 'center'
        },
        {
          title: this.$t('controllseason.c15'),
          dataIndex: "signalName",
          align: 'center'
        },
        {
          title: this.$t('controllseason.b13'),
          children: [
            {
              title: this.$t('controllseason.c21'),
              key: 'summerSetUpper',
              width: 120,
              scopedSlots: { customRender: 'summerSetUpper' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c22'),
              key: 'summerSetLower',
              width: 120,
              scopedSlots: { customRender: 'summerSetLower' },
              align: 'center'
            },
            {
              title: this.$t('controllseason.c17'),
              key: 'summerValid',
              width: 120,
              scopedSlots: { customRender: 'summerValid' },
              align: 'center'
            }    
          ],
          align: 'center'
        },  
        {
          title: this.$t('controllseason.b14'),
          children: [
            {
              title: this.$t('controllseason.c21'),
              key: 'winterSetUpper',
              width: 120,
              scopedSlots: { customRender: 'winterSetUpper' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c22'),
              key: 'winterSetLower',
              width: 120,
              scopedSlots: { customRender: 'winterSetLower' },
              align: 'center'
            }, 
            {
              title: this.$t('controllseason.c17'),
              key: 'winterValid',
              width: 120,
              scopedSlots: { customRender: 'winterValid' },
              align: 'center'
            }    
          ],
          align: 'center'
        }, 
        {
          title: this.$t('controllseason.c18'),
          children: [
            {
              title: this.$t('controllseason.c21'),
              key: 'otherSetUpper',
              width: 120,
              scopedSlots: { customRender: 'otherSetUpper' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c22'),
              key: 'otherSetLower',
              width: 120,
              scopedSlots: { customRender: 'otherSetLower' },
              align: 'center'
            },
            {
              title: this.$t('controllseason.c17'),
              key: 'otherValid',
              width: 120,
              scopedSlots: { customRender: 'otherValid' },
              align: 'center'
            }  
          ],
          align: 'center'
        }   
      ],
      slist: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch: {
    data:{
      handler(value){
        this.slist = JSON.parse(JSON.stringify(value));
      }
    }
  },
  methods: {
    radioChange(value, key, dataIndex) {
      const newData = [...this.slist];
      const target = newData.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value==1?true:false;
        console.log("修改数据boundCtrlTable1:",target);
        this.slist = newData;
        this.$emit('change',this.slist);
      }
    },      
    inputChange(value, key, dataIndex) {
      const newData = [...this.slist];
      const target = newData.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value;
        console.log("修改数据boundCtrlTable2:",target);
        this.slist = newData;
        this.$emit('change',this.slist);
      }
    },
  },
};
</script>
