<!--空调模块--模拟设置表格组件-->
<template>
  <a-table :columns="columns" :data-source="slist" :scroll="{ x: 1800 }" bordered :pagination="false">
    <span slot="summerSet" slot-scope="record">      
      <a-input  addon-after="℃" :defaultValue='record.setValue1_1' @change="e => inputChange(e.target.value, record.key, 'setValue1_1')"/>
    </span>
    <span slot="summerValid" slot-scope="record">
      <a-radio-group :defaultValue="record.enable1_1 ? 1 : 2" @change="e => radioChange(e.target.value, record.key, 'enable1_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span> 
       <span slot="winterSet" slot-scope="record">      
      <a-input  addon-after="℃"  :defaultValue="record.setValue2_1" @change="e => inputChange(e.target.value, record.key, 'setValue2_1')"/>
    </span>
    <span slot="winterValid" slot-scope="record"> 
      <a-radio-group :defaultValue="record.enable2_1?1:2" @change="e => radioChange(e.target.value, record.key, 'enable2_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span> 
    <span slot="otherSet" slot-scope="record">      
      <a-input  addon-after="℃"  :defaultValue="record.setValue3_1" @change="e => inputChange(e.target.value, record.key, 'setValue3_1')"/>
    </span>
    <span slot="otherValid" slot-scope="record"> 
      <a-radio-group :defaultValue="record.enable3_1?1:2" @change="e => radioChange(e.target.value, record.key, 'enable3_1')">
        <a-radio :value="1">
          {{ $t('controllseason.c12') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controllseason.c13') }}
        </a-radio>            
      </a-radio-group>
    </span>     
  </a-table>
</template>
<script>

export default {  
  props: {
    data: {
      type: Array,
    }
  },
  data() {
    return {
      columns:[
        {
          title: "NO",
          dataIndex: "key",
          width: 70,
          fixed: "left",
          align: 'center'
        },
        {
          title: this.$t('controllseason.c14'),
          dataIndex: "dvName",
          align: 'center'
        },
        {
          title: this.$t('controllseason.c15'),
          dataIndex: "signalName",
          align: 'center'
        },
        {
          title: this.$t('controllseason.b13'),
          children: [
            {
              title: this.$t('controllseason.c16'),
              key: 'summerSet',
              width: 120,
              scopedSlots: { customRender: 'summerSet' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c17'),
              key: 'summerValid',
              width: 250,
              scopedSlots: { customRender: 'summerValid' },
              align: 'center'
            },     
          ],
          align: 'center'
        },  
        {
          title: this.$t('controllseason.b14'),
          children: [
            {
              title: this.$t('controllseason.c16'),
              key: 'winterSet',
              width: 120,
              scopedSlots: { customRender: 'winterSet' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c17'),
              key: 'winterValid',
              width: 250,
              scopedSlots: { customRender: 'winterValid' },
              align: 'center'
            },     
          ],
          align: 'center'
        }, 
        {
          title: this.$t('controllseason.c18'),
          children: [
            {
              title: this.$t('controllseason.c16'),
              key: 'otherSet',
              width: 120,
              scopedSlots: { customRender: 'otherSet' },
              align: 'center'
            },  
            {
              title: this.$t('controllseason.c17'),
              key: 'otherValid',
              width: 250,
              scopedSlots: { customRender: 'otherValid' },
              align: 'center'
            },     
          ],
          align: 'center'
        },   
      ],
      slist: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch:{
    data:{
      handler(value){
        this.slist = JSON.parse(JSON.stringify(value))
      }
    }
  },
  methods: {
    radioChange(value, key, dataIndex) {
      const newData = [...this.slist];
      const target = newData.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value==1?true:false;
        console.log("修改数据analogCtrlTable1:",target);
        this.slist = newData;
        this.$emit('change',this.slist);
      }
    },      
    inputChange(value, key, dataIndex) {
      const newData = [...this.slist];
      const target = newData.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value;
        console.log("修改数据analogCtrlTable2:",target);
        this.slist = newData;
        this.$emit('change',this.slist);
      }
    },    
  },
};
</script>
