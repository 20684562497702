<!--空调模块--参数和运行模式设置页-->
<template>
  <div id="runmodeSeason" v-loading="loading" :element-loading-text="$t('controllseason.c1')">
    <!-- 页面标题 -->
    <my-PageTitle :quit="true">
      {{ $t('controllseason.c2') }}
      <p class="notes">( {{ seasonCtrlName }} )</p>
    </my-PageTitle>
    <!-- 标签页--模拟值、上下限值、运行模式列表 -->
    <div class="main">
      <a-tabs default-active-key="1" @change="callback" style="height: 100%;">
        <a-tab-pane key="1" :tab="$t('controllseason.c3')"> 
            <my-tabletitle>{{$t('controllseason.c4')}}</my-tabletitle>
            <my-outCtrlTable :data="analogList"></my-outCtrlTable>
            <my-tabletitle>{{$t('controllseason.c5')}}</my-tabletitle> 
            <my-analogCtrlTable :data="analogSetList" @change="analogChange"></my-analogCtrlTable>
        </a-tab-pane> 
        <a-tab-pane key="2" :tab="$t('controllseason.c6')"> 
            <my-tabletitle>{{$t('controllseason.c7')}}</my-tabletitle>
            <my-outCtrlTable :data="boundList"></my-outCtrlTable>
            <my-tabletitle>{{$t('controllseason.c8')}}</my-tabletitle>
            <my-boundCtrlTable :data="boundSetList" @change="boundChange"></my-boundCtrlTable>
        </a-tab-pane> 
        <a-tab-pane key="3" :tab="$t('controllseason.c9')">  
            <my-tabletitle>{{$t('controllseason.c9')}}</my-tabletitle>
            <my-outCtrlTable :data="runModeList"></my-outCtrlTable>
            <my-tabletitle>{{$t('controllseason.c10')}}</my-tabletitle>
            <my-runmodeCtrlTable :data="runmodeSetList" @change="runmodeChange"></my-runmodeCtrlTable>           
        </a-tab-pane> 
      </a-tabs>      
    </div>
    <!-- 底栏 -->
    <div class="footer">
      <a-button type="primary" style="width: 120px" :disabled="disabled" @click="update">{{$t('controllseason.c11')}}</a-button>
    </div>    
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle';
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { getSeasonCtrlInfo, getSeasonCtrlSetInfo, updateAirConditionSetInfo } from "../../api/device";
import analogCtrlTable from "./analogCtrlTable";
import boundCtrlTable from "./boundCtrlTable";
import runmodeCtrlTable from "./runmodeCtrlTable";
import outCtrlTable from "../common/outCtrlTable";

export default {
  name: "runmodeSeason",
  data() {
    return {
      loading: false,
      tabskey:"1",
      buttonloading: false,
      seasonCtrlName: this.$route.query.nodeName,
      analogList: [],
      boundList: [],
      runModeList:[],
      analogSetList: [],
      boundSetList: [],
      runmodeSetList:[],
      analogSetListChange: [],
      boundSetListChange: [],
      runmodeSetListChange: []
    };
  },
  mounted() {
    this.getData();    
  },
  computed:{
    disabled() {
      const analogSetListLength = this.analogSetListChange.length
      const boundSetListLength = this.boundSetListChange.length
      const runmodeSetListLength = this.runmodeSetListChange.length
      if (analogSetListLength != 0 || boundSetListLength != 0 || runmodeSetListLength !=0){
        return false
      }else{
        return true
      }
    }
  },
  methods: {
    callback(key) {
      this.tabskey = key;
    },
    analogChange(value){
      this.analogSetListChange = value;
      // console.log("修改后的数据runmodeSeason:",this.analogSetListChange);
    },
    boundChange(value){
      this.boundSetListChange = value;
      // console.log("修改后的数据runmodeSeason:",this.boundSetListChange);
    },
    runmodeChange(value){
      this.runmodeSetListChange = value;
      // console.log("修改后的数据runmodeSeason:",this.runmodeSetListChange);
    },

    //设置更新
    update() {
      this.loading = true
      let par = { 
        id: this.$route.query.nodeId, 
        analogInfos:this.analogSetListChange, 
        boundInfos:this.boundSetListChange, 
        runModeInfos:this.runmodeSetListChange 
      };      
      console.log("update runmodeSetListChange:",this.runmodeSetListChange);
      updateAirConditionSetInfo(par)
      .then((res) => {
        console.log(res);
        if (res.errorCode == "00") {
          this.$message.success(res.msg);
        } else if (res.errorCode == "02") {
          this.$message.error(res.msg);
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    // 获取当前已注册的认证终端数据
    getData() {
      this.loading = true;
      const getData1 = () => {
        let par = { siteId: this.$route.query.id, seasonCtrlId: this.$route.query.nodeId };
        return getSeasonCtrlInfo(par);
      };
      const getData2 = () => {
        let par = { seasonCtrlId: this.$route.query.nodeId };
        return getSeasonCtrlSetInfo(par);
      };
      Promise.all([getData1(), getData2()])
      .then(([response1, response2]) => {
        const data1 = response1.data;
        const data2 = response2.data;
        this.analogList = data1.analogList.map((item,index) => { item.key = index + 1; return item; });
        this.boundList = data1.boundList.map((item,index) => { item.key = index + 1; return item; });
        this.runModeList = data1.runModeList.map((item,index) => { item.key = index + 1; return item; });
        this.analogSetList = data2.analogInfos.map((item,index) => { item.key = index + 1; return item; });
        this.boundSetList = data2.boundInfos.map((item,index) => { item.key = index + 1; return item; });
        this.runmodeSetList = data2.runModeInfos.map((item,index) => { item.key = index + 1; return item; });
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.error('并发请求出错:', error);
      });
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
    "my-analogCtrlTable":analogCtrlTable,
    "my-boundCtrlTable":boundCtrlTable,
    "my-runmodeCtrlTable":runmodeCtrlTable,  
    "my-outCtrlTable":outCtrlTable,
    'my-PageTitle': PageTitle
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#runmodeSeason {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  overflow: hidden;
}
.main {
  width: 100%;
  height: calc(100% - 110px);
  overflow: hidden;
}
div /deep/.ant-tabs-bar{
  margin: 0px 20px 16px 20px;
}
div /deep/.ant-tabs-content{
  height: calc(100% - 60px);
}
div /deep/.ant-tabs-tabpane{
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.notes{
  font-size: 14px;
  margin: 0px 0px 0px 10px;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
</style>